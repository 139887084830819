.pageTitle {
    font-family: 'Arial', sans-serif; /* Choose a font that fits your aesthetic */
    text-align: center; /* Center the title */
    color: #4A4A4A; /* Aesthetic text color */
    margin-top: 20px; /* Space at the top */
    font-size: 2.5rem; /* Large font size for the title */
    text-shadow: 2px 2px 4px #AAA; /* Optional: Adds a subtle shadow for depth */
  }


.bubble-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start; ;
    padding: 20px;
  }
  
  .bubble {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #cccccc;
    color: black;
    cursor: pointer;
    user-select: none;
  }
  
  .bubble:hover {
    background-color: #999999;
  }

  .bubble.selected {
    background-color: #333333; /* Darker blue to indicate selection */
    color: white; 
  }

  .byline {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    font-size: 1rem;
  }
  
  .byline a {
    color: #0077cc; /* Example color - choose what fits your design */
    text-decoration: none; /* Optional: removes underline */
  }
  
  .byline a:hover {
    text-decoration: underline; /* Restores underline on hover for visual feedback */
  }