.recommendations-container {
  width: 100%; /* Use percentage-based widths for responsiveness */
  max-width: 600px; /* Optional: Set a max-width if needed */
  margin: auto; /* Center the container */
  padding: 0 15px; /* Add some padding on the sides */
}


.recommendations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recommendations-container {
    background-color: #f5f5f5;
    /* padding: 20px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .recommendations-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .recommendations-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .recommendations-container li {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  
  .recommendations-container li:before {
    content: "•";
    color: #ff6f00;
    font-size: 24px;
    margin-right: 10px;
  }
  
  .recommendations-container p {
    color: #666;
    /* font-style: italic; */
  }

  .description {
    white-space: pre-line;
  }

  .selectedOptionsSidebar {
    background-color: #f5f5f5;
    /* padding: 20px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }

  .selectedOptionsSidebar p, h2{
    margin: 0.5em; /* Adjust the value as needed */
  }

  .content-container {
    /* display: flex; */
    /*justify-content: space-between; /* Adjust as needed */
    /* flex-grow: 1; */
    display: grid;
    grid-template-columns: auto 20%; /* Adjust the sidebar width as needed */
    gap: 20px; /*
    
  }
  
  .selectedOptionsSidebar {
    /* Define width or flex-basis for the sidebar */
    flex-basis: 50%; /* Adjust the width as needed */
    /* Add additional styling for the sidebar */
  }

  .main-content {
    flex-grow: 1; /* Allows the main content to grow and fill the space */
  }

  @media (max-width: 768px) {
    .content-container {
      flex-direction: column; /* For Flexbox */
      /* or */
      grid-template-columns: 1fr; /* For Grid */
    }
  }